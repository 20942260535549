.cljWrapper{
    width: 100%;
    height: 100%;
    
}
.cljCoteinerBlock{
    width: 1440px;
    height: 100%;
    margin: 0 auto;
  
    padding-left: 70px;
    padding-top: 50px;
}
.cljBlockFirst{
    width: 1100px;
    height: 300px;

    display: flex;
    flex-direction: row;

}
.cljConteinerText{
    width: 900px;
    height: 300px;
  
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 20px;
}
.cljConteinerText p{
    font-size: 20px;
}
.cljConteinerText h2{
    font-size: 30px;
}
.cljBlockFoto{
    width: 1100px;
    height: 410px;
    
}
.pp{
    font-size: 20px;
    margin-left: 20px;
    margin-top: 20px;
}
.cljBlockFotos{
    width: 1000px;
    height: 700px;
   
    
}
.cljBlockFotos  img{
    width: 400px;
    height: 300px;
    border-radius: 15px;
    margin-right: 10px;
    margin-top: 10px;

}