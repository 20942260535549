.newBlok{
  
    width: 100%;
    height: 100%;

}

.newww{
   width: 1440px;
    margin: 0 auto;
    height: 500px;
}



.new0001{
  margin-left: 40px;
}


.newter{
  width: 1000px;
}