.headwrapper{
    width: 100%;
    height: 100%;
    background-color: antiquewhite;
    
}
.headContent{
    width: 1440px;
    height: 120px;
   
    
 
    margin: 0 auto;
}
.headBlockleft{
    width: 1440px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
   
}

.tagH3{
    font-family: serif;
    font-size: 20px;
    color: rgb(62, 57, 57);
}