.pwrapperi{
    width: 100%;
    height: 100%;
    
}
.pBlockContents{
    width: 1440px;
    height: 100%;
    
    margin: 0 auto;
}
.pBlockAboutText{
    width: 1440px;
    height: 150px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.pBlockAboutText p{
    font-size: 20px;
    color: rgb(122, 122, 250);
}
.pBlockAboutFotos{
    width: 1440px;
    height: 100%;
    
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding-left:100px;
}
.pBlockAboutFotos img{
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}
