.akkkkk{
     width: 100%;
    height: 100%;

}


.akak{
    width: 1440px;
    margin: 0 auto;
    height: 500px;
 
}


.ak010{
  display: flex;
   margin-left: 450px;
}

.ak020{
  margin-left: 320px;
  display: flex;
}